<template>
  <div class="p-grid mail-box">
    <div v-if="profiles.length !== 0 || loading" class="p-col-12">
      <view-switcher
        :layout="layout"
        :loading="loading"
        :length="profiles.length"
        @toggleView="toggleView"
      />
      <div>
        <list-view
          v-if="layout === 'list'"
          :profiles="profiles"
          :loading="loading"
          :profile-number="profileNumber"
          :load-profiles="loadProfiles"
          @updateMails="removeFromMails"
          @blockUser="blockUser"
        >
          <!-- deleted -->
          <template #deletedProfile="{}">
            <div class="list-item">
              <div class="item-itself">
                <p class="for-text-list for-text-list-del p-text-center p-p-0">
                  This profile is deleted
                </p>
              </div>
            </div>
          </template>
          <template #activeProfile="{ profile }">
            <div
              v-if="selectedContact !== profile.data.attributes.id"
              class="list-item footer-content-list"
            >
              <div class="item-itself p-text-center for-text-list-rej">
                Rejected
              </div>
            </div>
          </template>
        </list-view>
        <grid-view
          v-else
          :profiles="profiles"
          :loading="loading"
          :profile-number="profileNumber"
          :load-profiles="loadProfiles"
          @updateMails="removeFromMails"
          @blockUser="blockUser"
        >
          <template #default="{ profile }">
            <div class="footer-content p-p-0">
              <div class="p-d-flex p-jc-between footer-content">
                <p class="for-text-del p-text-center p-d-flex p-ai-center pad">
                  {{
                    profile.data.attributes.user_is_active
                      ? 'Rejected'
                      : 'This profile is deleted'
                  }}
                </p>
              </div>
            </div>
          </template>
        </grid-view>
      </div>
    </div>
    <div v-else class="p-col-12">
      <empty-mails
        :empty-text="'Currently you don\'t have any declines.'"
        :img="'/rejected.png'"
      />
    </div>
  </div>
</template>

<script>
import GridView from '@/components/views/GridView.vue';
import ListView from '@/components/views/ListView.vue';
import '@/assets/ListAndGridView.scss';
import EmptyMails from '@/components/EmptyMails.vue';
import { mailboxMixin } from '@/mixins/mailboxMixin';
import ViewSwitcher from '@/utilities/ViewSwitcher.vue';

export default {
  components: {
    GridView,
    ListView,
    EmptyMails,
    ViewSwitcher,
  },
  mixins: [mailboxMixin],
  data() {
    return {
      reqUrl: 'interests/accepted_or_rejected/?status=rejected',
    };
  },
};
</script>
